/** @jsxImportSource @emotion/react */

import _ from 'lodash'
import { Grid } from '@material-ui/core'
import EstimateCard from '../../components/EstimateCard'

const EstimatesCategories = ({ activities, estimates }) => {
  const data = _(activities.concat(estimates))
    .groupBy('category.name')
    .map((objs, key) => {
      const Ah = _.sumBy(objs, 'minutes') / 60 || 0
      const Eh = _.sumBy(objs, 'hours') || 0
      const workDone = Eh ? (Ah / Eh) * 100 : 0
      const workLeft = Eh - Ah

      const l_activities = objs.filter(
        (obj) => obj.__typename === 'Activity'
      ).length
      const l_estimates = objs.filter(
        (obj) => obj.__typename === 'Estimate'
      ).length

      const companies = _(objs)
        .groupBy('company.name')
        .map((comps, id) => {
          const cAh = _.sumBy(comps, 'minutes') / 60 || 0
          const cEh = _.sumBy(comps, 'hours') || 0
          const cWorkDone = cEh ? (cAh / cEh) * 100 : 0
          const cWorkLeft = cEh - cAh

          const authors = _(comps)
            .groupBy('author._id')
            .map((athrs, id) => {
              const aAh = _.sumBy(athrs, 'minutes') / 60 || 0
              const aWorkDone = (aAh / cAh) * 100

              return {
                name:
                  athrs[0]?.author?.firstName +
                  ' ' +
                  (athrs[0]?.author?.lastName
                    ? athrs[0]?.author?.lastName
                    : ''),

                color: athrs[0]?.author?.color,
                image: athrs[0]?.author?.image,
                h_activities: aAh,
                workDone: aWorkDone,
                tasks: athrs,
              }
            })
            .sortBy(['workLeft', 'h_activities', 'name'])
            .value()

          return {
            name: id,
            color: comps[0].company.color,
            icon: comps[0].company.icon,
            h_activities: cAh,
            h_estimates: cEh,
            workDone: cWorkDone,
            workLeft: cWorkLeft,
            authors: authors.filter((e) => e.h_activities),
            estimateIds: comps
              .filter(({ __typename }) => __typename === 'Estimate')
              ?.map(({ _id }) => _id),
          }
        })
        .sortBy(['workLeft', 'h_activities', 'name'])
        .value()

      return {
        id: objs[0].category?._id,
        name: key,
        color: objs[0].category?.color,
        h_activities: Ah,
        h_estimates: Eh,
        l_activities,
        l_estimates,
        type: objs[0].category?.type,
        workDone,
        workLeft,
        items: companies,
        typename: 'Category',
        estimateIds: objs
          .filter(({ __typename }) => __typename === 'Estimate')
          ?.map(({ _id }) => _id),
      }
    })
    .sortBy(['workLeft', 'h_activities', 'name'])
    .value()

  return (
    <div>
      <Grid container spacing={3}>
        {data.map((category, id) => (
          <Grid item xs={12} lg={6} key={id}>
            <EstimateCard data={category} />
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

export default EstimatesCategories
