import { gql } from '@apollo/client'

export const PROJECT_UPDATE = gql`
  mutation projectUpdate($id: ID!, $input: ProjectUpdateInput) {
    projectUpdate(id: $id, input: $input) {
      _id
      name
      description
      color
      isHidden
      company {
        _id
        name
        icon
      }
    }
  }
`

export const PROJECT_CREATE = gql`
  mutation projectCreate($input: ProjectCreateInput!) {
    projectCreate(input: $input) {
      _id
      name
      description
      color
      isHidden
      company {
        _id
        name
        icon
      }
    }
  }
`
export const GET_PROJECTS_SETTINGS = gql`
  query getProjects {
    getProjects {
      _id
      name
      description
      color
      isHidden
      company {
        _id
        name
        icon
      }
    }
  }
`

export const GET_PROJECTS = gql`
  query getProjects {
    getProjects {
      _id
      name
      description
      color
      isHidden
      company {
        _id
        name
        icon
        color
      }
    }
  }
`

export const GET_PROJECT_PROJECTS = gql`
  query getProjects {
    getProjects {
      _id
      name
      description
      color
      isHidden
      company {
        _id
        name
        icon
        color
      }
      monthActivs
      monthEstims
      monthCategories {
        name
        color
        monthActivs
        monthEstims
        monthWorkDone
      }
      clickupTasks
    }
  }
`
