import { gql } from '@apollo/client'

export const GET_COMPANIES_NAMES = gql`
  query getCompanies {
    getCompanies {
      _id
      name
      icon
      isHidden
      type
      require_projects
    }
  }
`

export const COMPANY_UPDATE = gql`
  mutation companyUpdate($id: ID!, $input: CompanyUpdateInput) {
    companyUpdate(id: $id, input: $input) {
      _id
      name
      description
      websites
      nip
      type
      cph_default
      isHidden
      icon
      color
      require_projects
    }
  }
`

export const COMPANY_CREATE = gql`
  mutation companyCreate($input: CompanyCreateInput!) {
    companyCreate(input: $input) {
      _id
      name
      description
      websites
      nip
      type
      cph_default
      isHidden
      icon
      color
      require_projects
    }
  }
`

export const GET_COMPANIES_SETTINGS = gql`
  query getCompanies {
    getCompanies {
      _id
      name
      description
      websites
      nip
      type
      cph_default
      isHidden
      icon
      color
      require_projects
      projects {
        _id
      }
    }
  }
`
