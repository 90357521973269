/** @jsxImportSource @emotion/react */

import { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { GET_USERS } from '../../graphql/user.graphql'
import { Skeleton } from '@material-ui/lab'
import { GET_ACTIVITIES } from '../../graphql/activity.graphql'
import { getToday } from '../../utils/getToday'
import { imageLink } from '../../utils/imageLink'
import {
  Avatar,
  Grid,
  IconButton,
  Paper,
  Collapse,
  Tooltip,
} from '@material-ui/core'
import { alpha, s } from '../../style'
import MailIcon from '@material-ui/icons/Mail'
import PhoneIcon from '@material-ui/icons/Phone'
// import { sTableCategory } from '../Tracking/Tracking'
// import { roleToColor, roleValueToName } from '../../utils'
import { timeDiff } from '../../utils/timeDiff'
import { minutesToTime } from '../../utils/minutesToTime'
import { useAuthContext } from '../../context/auth.context'

import { roleToColor, roleValueToName } from '../../utils'
import ColorLine from '../../components/ColorLine'
// import { hoursToTime } from '../../utils/hoursToTime'
import {
  sPaper,
  sTitle,
  sSubtitle,
  sIconSmall,
} from '../../components/EstimateCard'
import ClickupIcon from '../../assets/ClickupIcon'

const TeamNow = ({ now }) => {
  const today = getToday(new Date())

  const { userRole } = useAuthContext()

  const {
    data: usersData,
    loading: usersLoading,
    error: usersError,
  } = useQuery(GET_USERS)

  const {
    data: activitiesData,
    loading: activitiesLoading,
    error: activitiesError,
    startPolling,
    stopPolling,
  } = useQuery(GET_ACTIVITIES, {
    variables: {
      dateFrom: today,
      dateTo: today,
    },
    skip: userRole <= 1,
  })

  useEffect(() => {
    startPolling(15 * 60 * 1000) // 5 min
    return () => {
      stopPolling()
    }
  })

  if (usersLoading)
    return (
      <div css={sOuter}>
        {' '}
        <Skeleton variant='rect' height={300} css={{ width: '100%' }} />
      </div>
    )

  if (usersError) return <div css={sOuter}>Error</div>

  let users = usersData.getUsers
    .filter((user) => user.isActive)
    .map((user) => ({
      ...user,
      activity:
        !activitiesLoading &&
        !activitiesError &&
        activitiesData?.getActivities
          .filter((activity) => activity.author._id === user._id)
          .filter((activity) => !activity.timeEnd)[0],
    }))
    .sort((a, b) => (a.activity ? -1 : 1))

  return (
    <Grid container spacing={3} justifyContent='space-between'>
      {users.map((user, id) => (
        <UserNow key={id} data={user} now={now} />
      ))}
    </Grid>
  )
}

const UserNow = ({ data, now }) => {
  const {
    firstName,
    lastName,
    email,
    title,
    role,
    phone,
    birthDate,
    color,
    image,
    activity,
  } = data

  const [open, setOpen] = useState(false)

  return (
    <Grid item xs={12} lg={4}>
      <Paper elevation={0} css={[sPaper(color)]} onClick={() => setOpen(!open)}>
        <Grid container>
          <Grid
            item
            xs={12}
            lg={12}
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div css={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <Avatar
                src={imageLink(image)}
                css={[
                  {
                    width: 32,
                    height: 32,
                    marginRight: '1rem',
                  },
                  !image && { backgroundColor: color },
                ]}
                alt={firstName + ' ' + lastName}
              >
                {firstName?.[0]}
                {lastName?.[0]}
              </Avatar>
              <h2 css={[sTitle(color)]}>
                <div css={{ display: 'flex', flexDirection: 'column' }}>
                  {firstName}&nbsp;
                  {lastName}
                  <span
                    css={{
                      opacity: 0.3,
                      fontWeight: 400,
                      fontSize: '0.675rem',
                    }}
                  >
                    {title}
                  </span>
                </div>
              </h2>
            </div>
          </Grid>
          <Tooltip
            title={
              activity
                ? `od ${timeDiff(
                    activity.timeStart,
                    now
                  )} minut ${activity.category.name.toUpperCase()} dla ${
                    activity.company.name
                  }`
                : ''
            }
          >
            <Grid item xs={12} css={{ margin: '1rem 0 0' }}>
              {activity && (
                <Grid container spacing={1} onClick={() => setOpen(!open)}>
                  <Grid item xs={12}>
                    <ColorLine
                      color={activity.category.color}
                      percentage={
                        (timeDiff(activity.timeStart, now) / 60 / 1) * 100
                      }
                      shine
                      title={
                        <>
                          {activity.clickup && (
                            <a
                              href={`clickup://t/${activity.clickup.replace(
                                '#',
                                ''
                              )}`}
                              target='_blank'
                              rel='noreferrer noopener'
                            >
                              <IconButton
                                size='small'
                                aria-label='edit'
                                css={{
                                  fontSize: '0.875rem',
                                  marginRight: '0.25rem',
                                  color: activity.category.color,
                                  svg: {
                                    width: '0.875rem',
                                    height: '0.875rem',
                                  },
                                }}
                              >
                                <ClickupIcon />
                              </IconButton>
                            </a>
                          )}
                          <Avatar
                            src={imageLink(activity.company.icon)}
                            css={[
                              sIconSmall,
                              !activity.company.icon && {
                                backgroundColor: activity.company.color,
                              },
                            ]}
                            alt={activity.company.name}
                          >
                            {activity.company.name[0]}
                          </Avatar>
                          {activity.title} - {activity.category.name}
                        </>
                      }
                    >
                      {minutesToTime(
                        timeDiff(activity.timeStart, now),
                        'HH:MM'
                      )}
                    </ColorLine>
                  </Grid>
                </Grid>
              )}
              <Collapse in={open} css={{ width: '100%' }}>
                <div
                  css={[
                    {
                      paddingTop: '1rem',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    },
                  ]}
                >
                  <div>
                    <div css={{ display: 'flex' }}>
                      <span css={[sSubtitle(roleToColor(role)), sSubtitle2]}>
                        {roleValueToName(role)}
                      </span>
                      <span css={[sSubtitle(color), sSubtitle2]}>{title}</span>
                    </div>
                  </div>
                  <div>
                    {phone && (
                      <IconButton
                        size='small'
                        href={`tel:${phone}`}
                        css={sIconButton(color)}
                      >
                        <PhoneIcon
                          fontSize='small'
                          css={{ color: 'inherit' }}
                        />
                      </IconButton>
                    )}
                    <IconButton
                      size='small'
                      href={`mailto:${email}`}
                      css={sIconButton(color)}
                      color='inherit'
                    >
                      <MailIcon fontSize='small' css={{ color: 'inherit' }} />
                    </IconButton>
                  </div>
                </div>
              </Collapse>
            </Grid>
          </Tooltip>
        </Grid>
      </Paper>
    </Grid>
  )
}

// const UserNowOld = ({ data, now }) => {
//   const {
//     firstName,
//     lastName,
//     email,
//     title,
//     role,
//     phone,
//     birthDate,
//     color,
//     image,
//     activity,
//   } = data

//   // const theme = activity ? activity.category.color : color
//   const theme = color

//   return (
//     <Grid item xs={12} lg={6}>
//       <div
//         css={{
//           borderRadius: '0.5rem',
//           padding: '2rem',

//           display: 'flex',
//           flexDirection: 'column',
//           background: `linear-gradient(to top right, ${theme.concat(
//             alpha[6]
//           )}, ${theme.concat(alpha[20])})`,
//         }}>
//         <div
//           css={{
//             display: 'flex',
//             justifyContent: 'space-between',
//             [s.sm_down]: { flexDirection: 'column' },
//           }}>
//           <div css={{ display: 'flex' }}>
//             <Avatar
//               src={imageLink(image)}
//               alt={firstName + ' ' + lastName}
//               css={[sIcon, !image && { backgroundColor: color }]}>
//               {firstName?.[0]}
//               {lastName?.[0]}
//             </Avatar>
//             <div css={{ display: 'flex', flexDirection: 'column' }}>
//               <span css={sTitle}>
//                 {firstName} {lastName}
//               </span>
//               <div>
//                 {/* <span css={[sRole(roleToColor(role))]}>
//                   {roleValueToName(role)}
//                 </span> */}
//                 <span
//                   css={[
//                     sSubtitle,
//                     {
//                       color: theme.concat(alpha[50]),

//                       fontWeight: 'bold',
//                       fontSize: '0.75rem',
//                       opacity: 0.75,
//                     },
//                   ]}>
//                   {title || email}
//                 </span>
//               </div>
//             </div>
//           </div>
//           <div
//             css={{
//               display: 'flex',
//               alignItems: 'center',
//               [s.sm_down]: { justifyContent: 'flex-end' },
//             }}>
//             {phone && (
//               <IconButton href={`tel:${phone}`} css={sIconButton(theme)}>
//                 <PhoneIcon css={{ color: 'inherit' }} />
//               </IconButton>
//             )}
//             <IconButton
//               href={`mailto:${email}`}
//               css={sIconButton(theme)}
//               color='inherit'>
//               <MailIcon css={{ color: 'inherit' }} />
//             </IconButton>
//           </div>
//         </div>
//         {activity && (
//           <>
//             <div
//               css={[sSeparator, { backgroundColor: theme.concat(alpha[50]) }]}
//             />
//             <div
//               css={{
//                 display: 'flex',
//                 justifyContent: 'space-between',
//                 [s.sm_down]: { flexDirection: 'column' },
//               }}>
//               <div css={{ display: 'flex' }}>
//                 {activity.company.icon && (
//                   <Avatar
//                     src={imageLink(activity.company.icon)}
//                     alt={activity.company.name}
//                     css={[
//                       sIcon,
//                       !image && { backgroundColor: activity.company.color },
//                       image && { borderRadius: 0 },
//                     ]}>
//                     {activity.company.name.split(' ')?.[0]?.[0]}
//                     {activity.company.name.split(' ')?.[1]?.[0]}
//                   </Avatar>
//                 )}
//                 <div css={{ display: 'flex', flexDirection: 'column' }}>
//                   <span css={sTitle}>{activity.company.name}</span>
//                   <div css={[sTableCategory(activity.category.color), sType]}>
//                     {activity.category.name}
//                   </div>
//                 </div>
//               </div>
//               <div
//                 css={{
//                   display: 'flex',
//                   alignItems: 'center',
//                   [s.sm_down]: {
//                     paddingTop: '1rem ',
//                     justifyContent: 'flex-end',
//                   },
//                 }}>
//                 <div css={[sSubtitle, { color: theme.concat(alpha[50]) }]}>
//                   {activity.title}
//                 </div>
//                 <span css={[sTime, { color: theme.concat(alpha[50]) }]}>
//                   {minutesToTime(timeDiff(activity.timeStart, now), 'HH:MM')}
//                 </span>
//               </div>
//             </div>
//           </>
//         )}
//       </div>
//     </Grid>
//   )
// }

const sOuter = {
  width: '100%',
  paddingTop: '2rem',
  '.MuiDataGrid-cell': { fontSize: '0.75rem' },
}

// const sIcon = {
//   width: 40,
//   height: 40,
//   marginRight: '1rem',
// }

// const sSeparator = {
//   margin: '2rem 0',
//   borderRadius: '0.5rem',
//   width: '100%',
//   height: 1.5,
//   opacity: 1,
//   boxShadow: 'inset 0px 0px 0px rgb(0 0 0 /50%)',
// }

// const sTitle = {
//   fontSize: '0.875rem',
//   fontWeight: 'bold',
//   letterSpacing: '-0.025em',
//   display: 'flex',
// }

// const sSubtitle = {
//   fontSize: '0.875rem',
//   fontStyle: 'italic',
//   opacity: 1,
//   textShadow: '0px 0px 0px rgb(0 0 0 /50%)',
// }

// const sRole = (color) => ({
//   width: 'max-content',
//   fontWeight: 'bold',
//   fontSize: 8,
//   padding: '0.25rem 0.5rem',
//   color: 'white',
//   backgroundColor: color,
//   borderRadius: 4,
//   marginRight: '0.5rem',
// })

// const sType = {
//   height: 'max-content',
//   width: 'max-content',
//   marginTop: '0.25rem',
// }

// const sTime = {
//   fontWeight: 'bold',
//   letterSpacing: '-0.025em',
//   fontSize: '0.875rem',
//   marginLeft: '1rem',
//   textShadow: '0px 0px 0px rgb(0 0 0 /50%)',
// }

const sIconButton = (color) => ({
  transition: 'all 0.2s ease-out',
  color: color.concat(alpha[50]),
  filter: 'drop-shadow(0px 0px 0px black)',
  '&:hover': { color: color.concat(alpha[100]) },
  marginLeft: '0.5rem',
})

const sSubtitle2 = {
  fontSize: 10,
  marginRight: '1rem',
  marginLeft: 0,
}

export default TeamNow
