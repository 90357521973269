/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react'
import _ from 'lodash'
import { useQuery } from '@apollo/client'
import PageTitle from '../../components/PageTitle'
import PageWrapper from '../../components/PageWrapper'
import Seo from '../../components/Seo'
import { GET_PROJECT_PROJECTS } from '../../graphql/project.graphql'
import { Skeleton } from '@material-ui/lab'
import { Collapse, Grid, Paper } from '@material-ui/core'
import { imageLink } from '../../utils/imageLink'
import ColorLine from '../../components/ColorLine'
import { colors, s } from '../../style'
import ClickupTaskChip from '../Clickup/ClickupTaskChip'

const Projects = () => {
  const { data, loading, error } = useQuery(GET_PROJECT_PROJECTS)

  // console.log(
  //   data?.getProjects.filter(
  //     (project) => project?.company?.name === 'But Kompany'
  //   )
  // )

  return (
    <>
      <Seo title='🔼 Projects' />
      <PageTitle>
        <h1>Projects</h1>
        <p>
          <span>
            Poniższe dane firm są sumaryczną analizą przynależnych projektów.
            Uwzględniane są aktywności oraz estymacje z bieżącego miesiąca. Dane
            o aktywnościach z nieokreślonym projektem{' '}
            <strong>nie są wliczone!</strong>
          </span>
        </p>
      </PageTitle>
      <PageWrapper>
        <PageWrapper>
          {loading ? (
            <Skeleton variant='rect' height={300} css={{ width: '100%' }} />
          ) : error ? (
            <div>Error {error.message.toString()}</div>
          ) : (
            <ProjectsContainer
              data={data.getProjects.filter(
                (project) => project.monthActivs + project.monthEstims > 0
              )}
            />
          )}
        </PageWrapper>
      </PageWrapper>
    </>
  )
}

const ProjectsContainer = ({ data }) => {
  const companies = _(data)
    .groupBy('company.name')
    .map((objs, key) => {
      const monthActivsTotal = objs.reduce(
        (sum, curr) => curr.monthActivs + sum,
        0
      )
      const monthEstimsTotal = objs.reduce(
        (sum, curr) => curr.monthEstims + sum,
        0
      )
      const workDone = monthEstimsTotal
        ? (monthActivsTotal / monthEstimsTotal) * 100
        : 0
      const workLeft = monthEstimsTotal - monthActivsTotal

      return {
        name: objs[0].company.name,
        color: objs[0].company.color,
        icon: objs[0].company.icon,
        monthActivsTotal,
        monthEstimsTotal,
        workDone,
        workLeft,
        projects: objs,
      }
    })
    .value()

  return (
    <div>
      {companies.map(
        (
          {
            name,
            icon,
            color,
            projects,
            monthActivsTotal,
            monthEstimsTotal,
            workDone,
          },
          id
        ) => (
          <Grid container spacing={3} css={sCompanyOuter}>
            <Grid item xs={12} css={sCompanyInner}>
              <div css={sCompanyText}>
                {icon ? (
                  <img src={imageLink(icon)} css={sCompanyImage} alt={name} />
                ) : (
                  <div
                    css={[
                      sCompanyImage,
                      { backgroundColor: color, borderRadius: '2rem' },
                    ]}
                  />
                )}
                <strong>{name}</strong>
              </div>
              <Grid item xs={12} lg={3} css={sCompanyColorline}>
                <ColorLine
                  isOvertime={workDone >= 101}
                  isOverWarning={workDone >= 90}
                  isOverSoon={workDone >= 80}
                  shine={workDone >= 80}
                  lightBack
                  color={color ? color : colors.gray300}
                  percentage={workDone}
                  title={
                    <>
                      {monthActivsTotal.toFixed(0)} / {monthEstimsTotal} h
                    </>
                  }
                >
                  <div>{workDone.toFixed(0)}%</div>
                </ColorLine>
              </Grid>
            </Grid>
            {projects
              .filter(
                (project) =>
                  project.monthActivs >= 0 || project.monthEstims >= 0
              )
              .map((project, id) => (
                <ProjectContainer key={id} data={project} />
              ))}
          </Grid>
        )
      )}
    </div>
  )
}

const ProjectContainer = ({ data }) => {
  const {
    name,
    description,
    color,
    monthActivs,
    monthEstims,
    monthCategories,
    isHidden,
    clickupTasks,
  } = data

  // console.log(clickupTasks)

  const workDone = monthEstims ? (monthActivs / monthEstims) * 100 : 0

  const [open, setOpen] = useState(false)
  const [isOvertime, setIsOvertime] = useState(workDone >= 101)
  const [isOverWarning, setIsOverWarning] = useState(workDone >= 90)
  const [isOverSoon, setIsOverSoon] = useState(workDone >= 80)

  return (
    <Grid item xs={12} lg={4} css={isHidden && { opacity: 0.5 }}>
      <Paper
        elevation={0}
        css={[
          sPaper,
          isOverSoon && {
            animation:
              'alert-company-oversoon 0.6s linear alternate both infinite',
          },
          isOverWarning && {
            animation:
              'alert-company-overwarning 0.4s linear alternate both infinite',
          },
          isOvertime && {
            animation:
              'alert-company-overtime 0.2s linear alternate both infinite',
          },
        ]}
        onClick={() => setOpen(!open)}
      >
        <Grid container css={{ justifyContent: 'space-between' }}>
          <Grid
            item
            xs={12}
            lg={12}
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div
              css={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
              }}
            >
              <div css={{ display: 'flex', justifyContent: 'space-between' }}>
                <div css={sTitleContainer}>
                  <div
                    css={{
                      width: 12,
                      height: 12,
                      backgroundColor: color,
                      transform: 'rotate(45deg)',
                      marginRight: '1rem',
                      flexShrink: 0,
                    }}
                  />
                  <div css={sTitle}>
                    {name}{' '}
                    {isHidden && <span css={{ opacity: 0.5 }}>UKRYTY</span>}
                  </div>
                </div>
              </div>
              <Collapse in={open} collapsedSize={0}>
                <p css={sDescription}>{description}</p>
                {clickupTasks?.length > 0 && (
                  <div
                    css={{
                      display: 'flex',
                      marginTop: '1rem',
                      flexWrap: 'wrap',
                      a: {
                        marginRight: '0.5rem',
                        cursor: 'pointer !important',
                        [s.xs]: { maxWidth: 'calc(100vw - 6rem)' },
                      },
                    }}
                  >
                    {clickupTasks.map((taskId, id) => (
                      <a
                        key={id}
                        href={`clickup://t/${taskId.replace('#', '')}`}
                        target='_blank'
                        rel='noreferrer noopener'
                      >
                        <ClickupTaskChip taskId={taskId} />
                      </a>
                    ))}
                  </div>
                )}
              </Collapse>
            </div>
          </Grid>
          <Grid item xs={12} css={{ margin: '1rem 0 0' }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <ColorLine
                  color={!workDone ? colors.gray100 : color}
                  percentage={workDone}
                  isOvertime={isOvertime}
                  isOverWarning={isOverWarning}
                  isOverSoon={isOverSoon}
                  shine={isOverSoon}
                  lightBack
                  title={
                    <>
                      {monthActivs.toFixed(0)} / {monthEstims} h
                    </>
                  }
                >
                  <div>{workDone.toFixed(0)}%</div>
                </ColorLine>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Collapse in={open}>
              <Grid
                container
                spacing={1}
                css={{
                  justifyContent: 'flex-end',
                  marginTop: '0.5rem',
                }}
              >
                {_.sortBy(monthCategories, ['monthActivs'])
                  .reverse()
                  .map((category, id) => (
                    <ProjectCategory
                      key={id}
                      data={category}
                      setIsOvertime={setIsOvertime}
                      setIsOverWarning={setIsOverWarning}
                      setIsOverSoon={setIsOverSoon}
                      parentMonthEstims={monthEstims}
                    />
                  ))}
              </Grid>
            </Collapse>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}

const ProjectCategory = ({
  data,
  setIsOvertime,
  setIsOverSoon,
  setIsOverWarning,
  parentMonthEstims,
}) => {
  const { name, color, monthActivs, monthEstims, monthWorkDone } = data
  const workDone = monthEstims ? (monthActivs / monthEstims) * 100 : 0
  const isOvertime = monthWorkDone >= 101
  const isOverWarning = monthWorkDone >= 90
  const isOverSoon = monthWorkDone >= 80

  useEffect(() => {
    if (isOvertime) setIsOvertime(true)
    if (isOverSoon) setIsOverSoon(true)
    if (isOverWarning) setIsOverWarning(true)
  }, [
    isOvertime,
    isOverSoon,
    isOverWarning,
    setIsOvertime,
    setIsOverSoon,
    setIsOverWarning,
  ])

  return (
    <Grid item xs={11}>
      <ColorLine
        extraCss={{ width: '100%' }}
        color={!color ? colors.gray100 : color}
        percentage={monthWorkDone || (monthActivs / parentMonthEstims) * 100}
        isOvertime={isOvertime}
        isOverWarning={isOverWarning}
        isOverSoon={isOverSoon}
        shine={isOverSoon}
        lightBack
        title={<>{name === 'undefined' ? 'Estymacja bez kategorii' : name}</>}
      >
        <div>
          {monthActivs.toFixed(0)} / {monthEstims} h &nbsp;&nbsp;
          {workDone.toFixed(0)}%
        </div>
      </ColorLine>
    </Grid>
  )
}

const sPaper = {
  background: 'white',
  padding: '1rem',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  cursor: 'pointer',
  // height: '100%',
  // boxShadow: '2px 2px 8px rgb(0 0 0 / 8%)',
}

const sTitleContainer = {
  display: 'flex',
  alignItems: 'center',
}

const sTitle = {
  fontSize: '0.75rem',
  fontWeight: 'bold',
}

const sCompanyOuter = {
  paddingBottom: '4rem',
  marginBottom: '4rem',
  borderBottom: '1px solid rgb(0 0 0 / 25%)',
}

const sCompanyInner = {
  display: 'flex',
  flexDirection: 'column',
  // alignItems: 'center',
  [s.sm_down]: {
    alignItems: 'flex-start',
  },
  marginBottom: '2rem',

  strong: {
    fontSize: '0.75rem',
    fontWeight: 'bold',
  },
}

const sCompanyText = {
  display: 'flex',
  alignItems: 'center',
  // [s.xs]: { padding: '0 1rem' },
}

const sCompanyImage = {
  width: 32,
  height: 32,
  marginRight: '1rem',
}

const sCompanyColorline = {
  marginTop: '1rem',
  width: '100%',
  height: 'max-content',
  // [s.xs]: {
  //   width: 'calc(100% - 2rem)',
  //   margin: '1rem auto 0',
  // },
}

const sDescription = {
  fontSize: '0.75rem',
  opacity: 0.6,
  paddingTop: '1rem',
}

export default Projects
