/** @jsxImportSource @emotion/react */

import { useState, useEffect } from 'react'
import { imageLink } from '../../utils/imageLink'
import { sTableCategory } from '../Tracking/Tracking'
import moment from 'moment'
import { s, colors, alpha } from '../../style'
import { Tooltip, IconButton } from '@material-ui/core'
import StartIcon from '@material-ui/icons/PlayArrow'
import StopIcon from '@material-ui/icons/Stop'
import EditIcon from '@material-ui/icons/Edit'
import ClickupIcon from '../../assets/ClickupIcon'
import QRIcon from '@material-ui/icons/CropFree'
import { getURL } from '../QRCodes/QRInputs'

const date = new Date()
  .toLocaleDateString('pl', { timeZone: 'Europe/Warsaw' })
  .split('.')
  .reverse()
  .join('-')

const TrackDetails = ({
  data,
  paused,
  setEditActivityOpen,
  onStopActivity,
  onStartActivity,
  setViewCode,
  setScannerView,
}) => {
  const [now, setNow] = useState(
    data.timeEnd ? data.timeEnd + ':00' : new Date().toString().slice(16, 24)
  )
  const timeStart = moment(date + ' ' + data.timeStart + ':00')
  const timeNow = moment(date + ' ' + now)

  // console.log(timeStart)
  // console.log(timeNow)

  // console.log(data)

  useEffect(() => {
    const interval = setInterval(() => {
      if (!paused) setNow(new Date().toString().slice(16, 24))
    }, 1000)

    return () => clearInterval(interval)
  }, [setNow, paused])

  return (
    <>
      <div css={sContainer}>
        {timeNow && (
          <span
            css={[
              sCounter,
              paused && { color: colors.red, animation: 'blink 1s infinite' },
            ]}>
            {moment
              .utc(timeNow.diff(timeStart))
              .format('HH:mm:ss')
              .split(':')
              .map((e, id) => (
                <span
                  key={id}
                  css={[
                    id === 0 && e === '00' && { opacity: 0.16 },
                    id === 2 && { opacity: 0.3 },
                  ]}>
                  {e}
                  {id < 2 && (
                    <span
                      css={[
                        {
                          position: 'relative',
                          top: -8,
                          left: 0,
                          color: colors.black.concat(alpha[30]),
                        },
                        paused && { color: colors.red },
                      ]}>
                      :
                    </span>
                  )}
                </span>
              ))}
          </span>
        )}
        <img
          src={imageLink(data.company.icon)}
          alt=''
          css={[sImage, paused && { filter: 'grayscale(1)', opacity: 0.3 }]}
        />

        <div
          css={[
            sCompany,
            {
              color: data.company.color.concat(alpha[50]),
              textShadow: '0px 0px 0px rgba(0,0,0,0.8)',
            },
            paused && { filter: 'grayscale(1)', opacity: 0.3 },
          ]}>
          {data.company.name}
        </div>
        {data.project && (
          <div
            css={[
              sTableCategory(data.project.color),
              {
                backgroundColor: data.project.color.concat(alpha[8]),
                marginBottom: '1rem',
              },
              paused && { filter: 'grayscale(1)', opacity: 0.6 },
            ]}>
            <div
              css={{
                width: 8,
                height: 8,
                background: data.project.color,
                marginRight: 8,
                transform: 'rotate(45deg)',
              }}
            />
            {data.project.name}
          </div>
        )}
        <div
          css={[
            sTableCategory(data.category.color),
            paused && { filter: 'grayscale(1)', opacity: 0.66 },
          ]}>
          {data.category.name}
        </div>
        <div css={sTitle}>
          {process.env.NODE_ENV === 'development' && (
            <span
              css={{ fontSize: 10, fontWeight: 700, marginBottom: '0.5rem' }}>
              {data._id}
            </span>
          )}
          {data.title}
        </div>
        <div css={sButtons}>
          {!paused && (
            <Tooltip title={'STOP Tracking'}>
              <IconButton
                css={sIconButton}
                onClick={() =>
                  onStopActivity(
                    data._id,
                    new Date()
                      .toLocaleTimeString('pl', { timeZone: 'Europe/Warsaw' })
                      .slice(0, 5)
                  )
                }>
                <StopIcon fontSize='large' />
              </IconButton>
            </Tooltip>
          )}
          {paused && (
            <Tooltip title={'START Tracking'}>
              <IconButton
                css={[
                  sIconButton,
                  { backgroundColor: colors.red, color: colors.white },
                ]}
                onClick={() => onStartActivity()}>
                <StartIcon fontSize='large' />
              </IconButton>
            </Tooltip>
          )}

          <Tooltip title={'EDIT Activity'}>
            <IconButton
              css={sIconButton}
              onClick={() => setEditActivityOpen(data._id)}>
              <EditIcon fontSize='large' />
            </IconButton>
          </Tooltip>

          <Tooltip title={'SHOW QR'}>
            <IconButton
              css={sIconButton}
              onClick={() =>
                setViewCode({
                  ...data,
                  url: getURL({
                    auto: true,
                    clickup: data.clickup,
                    company: data.company,
                    project: data.project,
                    category: data.category,
                    title: data.title,
                  }),
                })
              }>
              <svg
                css={{ width: 32, height: 32 }}
                xmlns='http://www.w3.org/2000/svg'
                className='h-6 w-6'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
                strokeWidth={2}>
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M12 4v1m6 11h2m-6 0h-2v4m0-11v3m0 0h.01M12 12h4.01M16 20h4M4 12h4m12 0h.01M5 8h2a1 1 0 001-1V5a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1zm12 0h2a1 1 0 001-1V5a1 1 0 00-1-1h-2a1 1 0 00-1 1v2a1 1 0 001 1zM5 20h2a1 1 0 001-1v-2a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1z'
                />
              </svg>
            </IconButton>
          </Tooltip>
          <Tooltip title={'SCAN QR'}>
            <IconButton
              css={sIconButton}
              onClick={() => setScannerView((prev) => !prev)}>
              <QRIcon fontSize='large' />
            </IconButton>
          </Tooltip>
          {/* <Tooltip title={'OPEN ClickUp'}>
            <IconButton css={sIconButton} disabled={!data.clickup}>
              <ClickupIcon css={{ width: 32, height: 32 }} />
            </IconButton>
          </Tooltip> */}
        </div>
        {/* <div css={sScanner}>QR SCANNER</div> */}
      </div>
    </>
  )
}

const sContainer = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  padding: '4rem 2rem',
  animation: 'fadeIn 2s ease-in-out 0s both',
  [s.xs]: {
    padding: '3rem 2rem',
  },
}

const sCounter = {
  opacity: 0,
  display: 'flex',
  fontSize: '5rem',
  width: 280,
  letterSpacing: '-0.05em',
  textAlign: 'center',
  // fontWeight: 'lighter',
  [s.ss]: {
    fontSize: '4rem',
    width: 230,
  },
  animation: 'fadeIn 2s ease-in-out 1s both',
}

const sImage = {
  width: 96,
  height: 96,
  margin: '4rem 0 2rem',
  transition: 'all 0.3s ease-out',
}

const sCompany = {
  fontSize: '2rem',
  letterSpacing: '-0.025em',
  fontWeight: 700,
  margin: '0rem 0 1rem',
  textAlign: 'center',
}

const sProject = {
  fontSize: '0.75rem',
  letterSpacing: '-0.025em',
}

const sTitle = {
  fontSize: '0.875rem',
  // fontStyle: 'italic',

  margin: '2rem 0 0',
  padding: '1.5rem 2rem',
  backgroundColor: colors.gray100,
  color: colors.gray600,
  borderRadius: '1rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}

const sButtons = {
  margin: '3rem 0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  [s.sm_down]: {
    width: '100%',
  },
}

const sIconButton = {
  transition: 'all 0.2s ease-out',
  backgroundColor: colors.gray200,
  color: colors.gray500,
  margin: '0 1rem',
  '&:hover': {
    backgroundColor: colors.blue,
    color: colors.white,
  },
  '&:disabled': {
    color: colors.gray300 + ' !important',
  },
  [s.xs]: {
    margin: '0 0.75rem',
  },
  [s.ss]: {
    margin: '0 0.5rem',
  },
}

// const sScanner = {
//   width: 320,
//   padding: '1.5rem 2rem',
//   textTransform: 'uppercase',
//   backgroundColor: colors.gray300,
//   color: colors.gray600,
//   borderRadius: '2rem',
//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center',
//   fontWeight: 'bold',
//   letterSpacing: '0.05em',
//   [s.xs]: {
//     fontSize: '0.75rem',
//     position: 'fixed',
//     bottom: 0,
//     left: '50%',
//     transform: 'translateX(-50%)',
//     width: 'calc(100vw - 4rem)',
//     padding: '1.25rem',
//   },
// }

export default TrackDetails
