/** @jsxImportSource @emotion/react */

import { useState, useRef, useEffect } from 'react'
import { useAuthContext } from '../context/auth.context'
import { Link, NavLink, useLocation } from 'react-router-dom'
import UserInfo from './UserInfo'
import { INTERN, CHIEF, MANAGER } from '../constants'
import { roleNameToValue } from '../utils'
import citoLogo from '../assets/cito_logo.svg'
import { IconButton } from '@material-ui/core'
import Arrow from '@material-ui/icons/ArrowBack'
import { colors, s } from '../style'
import packageJson from '../../package.json'

import HomeIcon from '@material-ui/icons/Home'
import TimelapseIcon from '@material-ui/icons/Timelapse'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
// import BusinessIcon from '@material-ui/icons/Business'
import AccountTreeIcon from '@material-ui/icons/AccountTree'
import ExtensionIcon from '@material-ui/icons/Extension'
import GroupIcon from '@material-ui/icons/Group'
import WorkIcon from '@material-ui/icons/Work'
import AssistantPhotoIcon from '@material-ui/icons/AssistantPhoto'
// import FindInPageIcon from '@material-ui/icons/FindInPage'
import BuildIcon from '@material-ui/icons/Build'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import SystemUpdateIcon from '@material-ui/icons/SystemUpdate'
import SettingsIcon from '@material-ui/icons/Settings'
import AssignmentIcon from '@material-ui/icons/Assignment'
import CropFreeIcon from '@material-ui/icons/CropFree'
import { useShiftsContext } from '../context/shifts.context'

export const routes = [
  {
    name: 'Start',
    link: '/',
    icon: <HomeIcon fontSize='inherit' />,
    blocked: false,
    permission: INTERN,
  },
  {
    name: 'Estimates',
    link: '/estimates',
    icon: <AssistantPhotoIcon fontSize='inherit' />,
    blocked: false,
    permission: MANAGER,
  },
  {
    name: 'Tracking',
    link: '/tracking',
    icon: <TimelapseIcon fontSize='inherit' />,
    blocked: false,
    permission: INTERN,
  },
  {
    name: 'Activities',
    link: '/activities',
    icon: <CheckBoxIcon fontSize='inherit' />,
    blocked: false,
    permission: INTERN,
  },
  {
    name: 'Tasks',
    link: '/tasks',
    icon: <AssignmentIndIcon fontSize='inherit' />,
    blocked: false,
    permission: INTERN,
  },
  {
    name: 'Projects',
    link: '/projects',
    icon: <WorkIcon fontSize='inherit' />,
    blocked: false,
    permission: INTERN,
  },
  // {
  //   name: 'Companies',
  //   link: '/companies',
  //   icon: <BusinessIcon fontSize='inherit' />,
  //   blocked: true,
  //   permission: INTERN,
  // },
  {
    name: 'Team',
    link: '/team',
    icon: <GroupIcon fontSize='inherit' />,
    blocked: false,
    permission: INTERN,
  },
  {
    name: 'Shifts',
    link: '/shifts',
    icon: <AssignmentIcon fontSize='inherit' />,
    blocked: false,
    permission: INTERN,
  },

  // {
  //   name: 'Raports',
  //   link: '/raports',
  //   icon: <FindInPageIcon fontSize='inherit' />,
  //   blocked: true,
  //   permission: MANAGER,
  // },
  {
    name: 'Settings',
    link: '/settings',
    icon: <SettingsIcon fontSize='inherit' />,
    blocked: false,
    permission: CHIEF,
  },
  {
    name: 'QR Codes',
    link: '/qrcodes',
    icon: (
      <svg
        css={{ width: 24, height: 24 }}
        xmlns='http://www.w3.org/2000/svg'
        className='h-6 w-6'
        fill='none'
        viewBox='0 0 24 24'
        stroke='currentColor'
        strokeWidth={2}>
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M12 4v1m6 11h2m-6 0h-2v4m0-11v3m0 0h.01M12 12h4.01M16 20h4M4 12h4m12 0h.01M5 8h2a1 1 0 001-1V5a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1zm12 0h2a1 1 0 001-1V5a1 1 0 00-1-1h-2a1 1 0 00-1 1v2a1 1 0 001 1zM5 20h2a1 1 0 001-1v-2a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1z'
        />
      </svg>
    ),
    blocked: false,
    permission: INTERN,
  },
  {
    name: 'Version',
    link: '/version',
    icon: <SystemUpdateIcon fontSize='inherit' />,
    blocked: false,
    permission: INTERN,
  },
]

const Menu = () => {
  const location = useLocation()
  const [collapsed, setCollapsed] = useState(
    window?.innerWidth <= 800
      ? true
      : location.pathname === '/track'
      ? true
      : false
  )
  const [hasVersionSeen, setHasVersionSeen] = useState(true)
  const [hasOpenedTasks, setHasOpenedTasks] = useState(true)
  const { userRole } = useAuthContext()
  const { hasShift } = useShiftsContext()
  const refMenu = useRef(null)

  const scrollToTop = () => {
    refMenu.current?.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    if (location.pathname === '/track') setCollapsed(true)
  }, [location])

  useEffect(() => {
    setHasVersionSeen(localStorage.getItem(packageJson.version))
  }, [setHasVersionSeen])

  useEffect(() => {
    setHasOpenedTasks(
      localStorage.getItem('visited') === new Date().toISOString().slice(0, 10)
    )
  }, [setHasOpenedTasks])

  // useEffect(() => {
  //   console.log('Menu useEffect')
  //   if (userId && !hasShift) getTodaysShift()
  // }, [userId])

  return (
    <div css={[sMenu(collapsed)]} ref={refMenu}>
      <div css={sNav}>
        <div css={sTop}>
          <IconButton
            onClick={() => setCollapsed(!collapsed)}
            css={{ [s.sm_down]: { order: 2, transform: 'rotate(90deg)' } }}>
            <Arrow
              css={[
                { transition: 'transform 0.3s ease-out' },
                collapsed && {
                  transform: 'rotate(180deg)',
                },
              ]}
            />
          </IconButton>
          <Link
            to='/'
            css={[
              sLogo,
              collapsed && {
                [s.md]: {
                  opacity: 0,
                  transition: 'opacity 0.15s ease-out',
                },
              },
            ]}>
            <img
              src={citoLogo}
              alt='CITO Logo'
              css={[
                {
                  height: 24,
                  marginRight: '1rem',
                  [s.sm_down]: { height: 24 },
                },
              ]}
            />
          </Link>
        </div>
        <nav css={[sLinks, collapsed && { [s.xs]: { display: 'none' } }]}>
          {routes
            .filter((route) => userRole >= roleNameToValue(route.permission))
            .map((route, id) => (
              <div css={{ position: 'relative' }} key={id}>
                <NavLink
                  exact
                  onClick={() => {
                    if (route.link === '/version' && !hasVersionSeen) {
                      setHasVersionSeen(true)
                    }
                    if (route.link === '/tasks' && !hasOpenedTasks) {
                      setHasOpenedTasks(true)
                    }
                    if (window?.innerWidth <= 800) {
                      scrollToTop()
                      setCollapsed(true)
                    }
                  }}
                  to={route.link}
                  css={[sLink, route.blocked && { opacity: 0.3 }]}
                  activeStyle={sLinkActive}>
                  <div css={sLinkIcon}>{route.icon}</div>
                  <div css={[sText, collapsed && { opacity: 0 }]}>
                    {route.name}
                  </div>
                </NavLink>
                {route.link === '/version' && !hasVersionSeen && (
                  <div css={sBadge} />
                )}
                {/* {route.link === '/tasks' && !hasOpenedTasks && (
                  <div css={sBadge} />
                )} */}
                {route.link === '/shifts' && hasShift && (
                  <div
                    css={[
                      sBadge,
                      hasShift.isDone && { backgroundColor: colors.green },
                    ]}
                  />
                )}
              </div>
            ))}
        </nav>
      </div>
      <UserInfo collapsed={collapsed} />
    </div>
  )
}

const sBadge = {
  position: 'absolute',
  top: -4,
  left: -4,
  width: 12,
  height: 12,
  borderRadius: '50%',
  backgroundColor: 'red',
}

const sMenu = (collapsed) => ({
  transition:
    'width 0.3s ease-out, height 0.3s ease-in-out, background-color 0.3s ease-out',
  width: collapsed ? 112 : 300,
  // minWidth: collapsed ? 0 : 300,
  height: '100%',
  display: 'flex',
  // backgroundColor: collapsed ? '#e6e6e6' : '#cccccc',
  backgroundColor: '#e6e6e6',
  flexDirection: 'column',
  justifyContent: 'space-between',
  overflowY: 'auto',
  overflowX: 'hidden',
  flexShrink: 0,
  [s.sm_down]: {
    width: '100vw',
    height: collapsed ? '4rem' : '100vh',
    overflowY: collapsed ? 'hidden' : 'auto',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1000,
    transform: 'translate3d(0,0,0)',
    // backgroundColor: 'red',
  },
  '&::-webkit-scrollbar': {
    display: 'none',
  },
})

const sTop = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '2rem 0',
  [s.sm_down]: {
    padding: '0.5rem 0',
    paddingLeft: '1rem',
  },
}

const sLogo = {
  transition: 'opacity 0.3s ease-in-out 0.3s',
  fontSize: '0.75rem',
  letterSpacing: '0.5em',
  [s.sm_down]: { order: 1 },
}

const sNav = {
  display: 'flex',
  flexDirection: 'column',
  padding: '0 2rem',
  [s.sm_down]: {
    padding: '0 1rem',
  },
}

const sLinks = {
  display: 'flex',
  flexDirection: 'column',
  [s.sm_down]: {
    paddingTop: '1rem',
  },
}

export const sLink = {
  cursor: 'pointer',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  transition:
    'color 0.2s ease-out, background-color 0.2s ease-out, filter 0.2s ease-out, opacity 0.2s ease-out',
  fontSize: '0.75rem',
  fontWeight: 'bold',
  boxSizing: 'border-box',
  width: '100%',
  height: 48,
  margin: '0 0 1rem',
  overflow: 'hidden',
  backgroundColor: 'hsl(0deg 0% 80%)',
  borderRadius: '0.5rem',
  padding: '0 0.75rem',
  '&:hover': {
    // backgroundColor: 'hsl(0deg 0% 75%)',
    filter: 'contrast(1.1) saturate(0.9)',
  },
}

const sLinkActive = {
  backgroundColor: 'hsl(212deg 100% 86%)',
  color: '#007aff',
}

export const sText = {
  padding: '0 0.75rem',
  transition: 'opacity 0.3s ease-in-out',
  whiteSpace: 'nowrap',
}

export const sLinkIcon = {
  // position: 'absolute',
  // top: '50%',
  // left: '50%',
  // transform: 'translate(-50%, -50%)',
  fontSize: '1.5rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

export default Menu
